// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-pliki-cookies-js": () => import("./../src/pages/pliki-cookies.js" /* webpackChunkName: "component---src-pages-pliki-cookies-js" */),
  "component---src-pages-realizacje-index-js": () => import("./../src/pages/realizacje/index.js" /* webpackChunkName: "component---src-pages-realizacje-index-js" */),
  "component---src-pages-uslugi-index-js": () => import("./../src/pages/uslugi/index.js" /* webpackChunkName: "component---src-pages-uslugi-index-js" */),
  "component---src-templates-realization-page-js": () => import("./../src/templates/realization-page.js" /* webpackChunkName: "component---src-templates-realization-page-js" */)
}

